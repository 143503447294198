<template>
  <div class="div-main-list-page-table div-order-table" v-loading="isOrderTableLoading">
    <el-table
      v-if="!isOrderTableLoading"
      :data="orderTableData"
      height="100%"
      :border="true"
      :header-cell-style="tableHeaderCellStyle"
      :cell-style="tableCellStyle"
    >
      <el-table-column min-width="40" v-if="hasRadio">
        <template v-slot="scope">
          <el-radio :label="scope.row" v-model="orderRadioData" @change="handleOrderRadioSelectChange"><i></i></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="Order No." min-width="120px" show-overflow-tooltip>
        <template v-slot="scope">
          <div>{{scope.row.sellerSysOrganizationId === myCompanyId ? scope.row.salesOrderNo : scope.row.purchaseOrderNo}}</div>
        </template>
      </el-table-column>
      <el-table-column label="Status" min-width="150px">
        <template v-slot="scope">
          <div v-bind:class="{'mobile-status-tag': isMobileScreen,'status-tag':!isMobileScreen}" :style="getStatusTagStyle(scope.row.sellerSysOrganizationId === myCompanyId ? scope.row.sellerOrderStatus : scope.row.buyerOrderStatus)">
            {{scope.row.sellerSysOrganizationId === myCompanyId ? capitalizeFirstLetter(scope.row.sellerOrderStatus, '_') : capitalizeFirstLetter(scope.row.buyerOrderStatus, '_')}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Trade Type" min-width="80px">
        <template v-slot="scope">
          <div>
            {{scope.row.sellerSysOrganizationId === myCompanyId ? 'Sales' : 'Purchase'}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Job" min-width="40px">
        <template v-slot="scope">
          <div>
            {{scope.row.jobIds ? 'Yes' : 'No'}}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Order Id" min-width="40px">
        <template v-slot="scope">
          <div>
            {{scope.row.orderId ?? "-" }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="Company" min-width="200px" show-overflow-tooltip>
        <template v-slot="scope">
          <div>
            {{scope.row.sellerSysOrganizationId === myCompanyId ? scope.row.buyerCompanyName : scope.row.sellerCompanyName}}
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="orderType === ORDER_TYPE.DELIVERED || orderType === ORDER_TYPE.BARGING_NOMINATION || orderType === 'DELIVERED_AND_BARGING_NOMINATION'" label="Vessel" min-width="120px" show-overflow-tooltip>
        <template v-slot="scope">
          {{scope.row.vesselName || '-'}}
        </template>
      </el-table-column>
      <el-table-column v-else label="Terminal" min-width="120px" show-overflow-tooltip>
        <template v-slot="scope">
          {{scope.row.terminal || '-'}}
        </template>
      </el-table-column>
      <el-table-column v-if="orderType === ORDER_TYPE.DELIVERED || orderType === ORDER_TYPE.BARGING_NOMINATION || orderType === 'DELIVERED_AND_BARGING_NOMINATION'" label="ETA Range (From-To)" min-width="140px">
        <template v-slot="scope">
          {{`${$moment(scope.row.stemStartDate).format('DD-MM-YYYY')} - ${$moment(scope.row.stemEndDate).format('DD-MM-YYYY')}`}}
        </template>
      </el-table-column>
      <el-table-column v-else label="Loading Date (From-To)" min-width="140px">
        <template v-slot="scope">
            {{`${$moment(scope.row.stemStartDate).format('DD-MM-YYYY')} - ${$moment(scope.row.stemEndDate).format('DD-MM-YYYY')}`}}
        </template>
      </el-table-column>
      <el-table-column label="Product/Grade/Specs" min-width="220px" show-overflow-tooltip>
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            <el-tag :style="tableOrderProductTagStyle(item.status)" type="info">{{`${item.displayName}`}}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="(orderType === ORDER_TYPE.DELIVERED || orderType === ORDER_TYPE.BARGING_NOMINATION || orderType === 'DELIVERED_AND_BARGING_NOMINATION') ? 'Nominated Quantity' : 'Loading Quantity'" min-width="120px" show-overflow-tooltip>
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            <div v-if="item.finalQuantity">{{`${item.finalQuantity} ${item.unitMetric}`}}</div>
            <div v-else>-</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Min Quantity" min-width="100px" show-overflow-tooltip>
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            <div v-if="item.minimumQuantity">{{`${item.minimumQuantity} ${item.unitMetric}`}}</div>
            <div v-else>-</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Max Quantity" min-width="100px" show-overflow-tooltip>
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            <div v-if="item.maximumQuantity">{{`${item.maximumQuantity} ${item.unitMetric}`}}</div>
            <div v-else>-</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="(orderType === ORDER_TYPE.DELIVERED || orderType === ORDER_TYPE.BARGING_NOMINATION || orderType === 'DELIVERED_AND_BARGING_NOMINATION')" label="Physical Supplier" min-width="150px" show-overflow-tooltip>
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            {{item.physicalSupplierName}}
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="50px" key="dropdownMenu" fixed="right" v-if="hasTableMenu">
        <template v-slot="scope">
          <dropdown-action-menu
            :actionList="getOrderTableMenuList(scope.row)"
            :propsData="scope.row"
            @handleDropdownMenuClick="handleOrderTableMenuClick"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="div-main-list-page-table-pagination" v-if="hasPagination && !isOrderTableLoading">
      <el-pagination
        background
        @size-change="handleOrderPageSizeChange"
        @current-change="handleOrderPageChange"
        :page-size.sync="orderPagination.pageSize"
        :page-sizes="[5, 10, 20, 50, 100]"
        :current-page="orderPagination.pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="orderPagination.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import { ORDER_TYPE, ORDER_STATUS, HEADER_CELL_STYLE, CELL_STYLE,SMALL_CELL_STYLE,SMALL_HEADER_CELL_STYLE } from '@/constants.js'
import { getStatusTagStyle, capitalizeFirstLetter } from '@/utils/index.js'
// import { capitalizeFirstLetter } from '@/utils/index.js'
import DropdownActionMenu from '@/components/DropdownActionMenu'

export default {
  name: 'TableOrder',
  components: { DropdownActionMenu },
  props: {
    orderType: String,
    orderTableData: Array,
    hasTableMenu: Boolean,
    hasRadio: Boolean,
    isOrderTableLoading: Boolean,
    hasPagination: Boolean,
    orderPagination: Object,
    isAllVerified: Boolean
  },
  data () {
    const orderTableMenuTermList = [
      { key: 'view', label: 'View', icon: 'el-icon-view' },
      { key: 'linkOrder', label: 'Link Order', icon: 'el-icon-connection' },
      { key: 'unlinkOrder', label: 'Unlink Order', icon: 'el-icon-close' },
      { key: 'linkJobs', label: 'Link Jobs', icon: 'el-icon-connection' },
      { key: 'unlinkJobs', label: 'Unlink Jobs', icon: 'el-icon-close' },
      { key: 'attachment', label: 'Attachment', icon: 'el-icon-paperclip' },
      { key: 'viewBlockchainInfo', label: 'Blockchain Info', icon: 'el-icon-warning-outline' }
    ]
    return {
      ORDER_TYPE,
      ORDER_STATUS,
      HEADER_CELL_STYLE,
      CELL_STYLE,
      SMALL_CELL_STYLE,
      SMALL_HEADER_CELL_STYLE,
      orderRadioData: null,
      orderTableMenuTermList,
      blockchainInfoVisible: false
    }
  },
  computed: {
    ...mapState(["isMobileScreen"]),
    myCompanyId () {
      return this.$store.state.currentCompany.id
    },
    tableHeaderCellStyle(){
      return this.isMobileScreen ? this.SMALL_HEADER_CELL_STYLE : this.HEADER_CELL_STYLE
    },
    tableCellStyle(){
      return this.isMobileScreen ? this.SMALL_CELL_STYLE : this.CELL_STYLE
    }
  },
  methods: {
    getStatusTagStyle,
    capitalizeFirstLetter,
    handleOrderTableMenuClick (menuLabel, order) {
      const orderId = order.id
      this.$emit('handleOrderTableMenuClick', menuLabel, orderId)
    },
    handleOrderPageChange (pageNumber) {
      this.$emit('handleOrderPageChange', pageNumber)
    },
    handleOrderPageSizeChange (pageSize) {
      this.$emit('handleOrderPageSizeChange', pageSize)
    },
    handleOrderRadioSelectChange (selection) {
      this.$emit('handleOrderRadioSelectChange', selection)
    },
    handleCloseBlockchainInfo () {
      this.blockchainInfoVisible = false
    },
    getOrderTableMenuList (data) {
      const isSalesOrder = this.myCompanyId === data.sellerSysOrganizationId
      let list = this.orderTableMenuTermList
      if (this.orderType === ORDER_TYPE.EX_WHARF) {
        list = list.filter(item => item.key !== 'unlinkOrder' && item.key !== 'linkOrder')
        if (!(!this.isAllVerified && data.createdBySysOrganization === this.myCompanyId && ((isSalesOrder && data.sellerOrderStatus === ORDER_STATUS.CONFIRMED) || (!isSalesOrder && data.buyerOrderStatus === ORDER_STATUS.CONFIRMED)))) {
          list = list.filter(item => item.key !== 'linkJobs' && item.key !== 'unlinkJobs')
        }
      } else {
        if ((data.upstreamOrderId && data.sellerSysOrganizationId === this.myCompanyId) || (data.downstreamOrderId && data.buyerSysOrganizationId === this.myCompanyId)) {
          list = list.filter(item => item.key !== 'linkOrder')
        } else {
          list = list.filter(item => item.key !== 'unlinkOrder')
        }
        if (!(!this.isAllVerified && data.sellerSysOrganizationId === this.myCompanyId && data.sellerOrderStatus === ORDER_STATUS.CONFIRMED)) {
          list = list.filter(item => item.key !== 'linkJobs' && item.key !== 'unlinkJobs')
        }
      }
      if (!this.hasPermission(this.$permissionFunctionCode.EDIT)) {
        list = list.filter(item => item.key !== 'attachment')
      }
      return list
    },
    tableOrderProductTagStyle (productStatus) {
      switch (productStatus) {
        case 'VERIFIED':
        case 'READY_FOR_INVOICING':
        case 'INVOICE_CREATED':
          return { color: '#ffffff', backgroundColor: '#6fcf6d' }
        default:
          return { color: '#909399', backgroundColor: '#f4f4f5' }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
  .el-dropdown-item:hover {
    background-color: #E7EBF0;
    color: #3f5f88;
  }
</style>
