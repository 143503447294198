var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isOrderTableLoading,
          expression: "isOrderTableLoading",
        },
      ],
      staticClass: "div-main-list-page-table div-order-table",
    },
    [
      !_vm.isOrderTableLoading
        ? _c(
            "el-table",
            {
              attrs: {
                data: _vm.orderTableData,
                height: "100%",
                border: true,
                "header-cell-style": _vm.tableHeaderCellStyle,
                "cell-style": _vm.tableCellStyle,
              },
            },
            [
              _vm.hasRadio
                ? _c("el-table-column", {
                    attrs: { "min-width": "40" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: scope.row },
                                  on: {
                                    change: _vm.handleOrderRadioSelectChange,
                                  },
                                  model: {
                                    value: _vm.orderRadioData,
                                    callback: function ($$v) {
                                      _vm.orderRadioData = $$v
                                    },
                                    expression: "orderRadioData",
                                  },
                                },
                                [_c("i")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      699994751
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  label: "Order No.",
                  "min-width": "120px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.sellerSysOrganizationId ===
                                  _vm.myCompanyId
                                  ? scope.row.salesOrderNo
                                  : scope.row.purchaseOrderNo
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2673753114
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Status", "min-width": "150px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              class: {
                                "mobile-status-tag": _vm.isMobileScreen,
                                "status-tag": !_vm.isMobileScreen,
                              },
                              style: _vm.getStatusTagStyle(
                                scope.row.sellerSysOrganizationId ===
                                  _vm.myCompanyId
                                  ? scope.row.sellerOrderStatus
                                  : scope.row.buyerOrderStatus
                              ),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.sellerSysOrganizationId ===
                                      _vm.myCompanyId
                                      ? _vm.capitalizeFirstLetter(
                                          scope.row.sellerOrderStatus,
                                          "_"
                                        )
                                      : _vm.capitalizeFirstLetter(
                                          scope.row.buyerOrderStatus,
                                          "_"
                                        )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1431536911
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Trade Type", "min-width": "80px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.sellerSysOrganizationId ===
                                    _vm.myCompanyId
                                    ? "Sales"
                                    : "Purchase"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  939074074
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Job", "min-width": "40px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.jobIds ? "Yes" : "No") +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2873705571
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Company",
                  "min-width": "200px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.sellerSysOrganizationId ===
                                    _vm.myCompanyId
                                    ? scope.row.buyerCompanyName
                                    : scope.row.sellerCompanyName
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2753862977
                ),
              }),
              _vm.orderType === _vm.ORDER_TYPE.DELIVERED ||
              _vm.orderType === _vm.ORDER_TYPE.BARGING_NOMINATION ||
              _vm.orderType === "DELIVERED_AND_BARGING_NOMINATION"
                ? _c("el-table-column", {
                    attrs: {
                      label: "Vessel",
                      "min-width": "120px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.vesselName || "-") + " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1995850445
                    ),
                  })
                : _c("el-table-column", {
                    attrs: {
                      label: "Terminal",
                      "min-width": "120px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.terminal || "-") + " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4106616692
                    ),
                  }),
              _vm.orderType === _vm.ORDER_TYPE.DELIVERED ||
              _vm.orderType === _vm.ORDER_TYPE.BARGING_NOMINATION ||
              _vm.orderType === "DELIVERED_AND_BARGING_NOMINATION"
                ? _c("el-table-column", {
                    attrs: {
                      label: "ETA Range (From-To)",
                      "min-width": "140px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm
                                      .$moment(scope.row.stemStartDate)
                                      .format("DD-MM-YYYY")} - ${_vm
                                      .$moment(scope.row.stemEndDate)
                                      .format("DD-MM-YYYY")}`
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2589515743
                    ),
                  })
                : _c("el-table-column", {
                    attrs: {
                      label: "Loading Date (From-To)",
                      "min-width": "140px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm
                                      .$moment(scope.row.stemStartDate)
                                      .format("DD-MM-YYYY")} - ${_vm
                                      .$moment(scope.row.stemEndDate)
                                      .format("DD-MM-YYYY")}`
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2589515743
                    ),
                  }),
              _c("el-table-column", {
                attrs: {
                  label: "Product/Grade/Specs",
                  "min-width": "220px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(scope.row.products, function (item) {
                          return _c(
                            "div",
                            { key: item.id },
                            [
                              _c(
                                "el-tag",
                                {
                                  style: _vm.tableOrderProductTagStyle(
                                    item.status
                                  ),
                                  attrs: { type: "info" },
                                },
                                [_vm._v(_vm._s(`${item.displayName}`))]
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  1481571241
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label:
                    _vm.orderType === _vm.ORDER_TYPE.DELIVERED ||
                    _vm.orderType === _vm.ORDER_TYPE.BARGING_NOMINATION ||
                    _vm.orderType === "DELIVERED_AND_BARGING_NOMINATION"
                      ? "Nominated Quantity"
                      : "Loading Quantity",
                  "min-width": "120px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(scope.row.products, function (item) {
                          return _c("div", { key: item.id }, [
                            item.finalQuantity
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      `${item.finalQuantity} ${item.unitMetric}`
                                    )
                                  ),
                                ])
                              : _c("div", [_vm._v("-")]),
                          ])
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  135055011
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Min Quantity",
                  "min-width": "100px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(scope.row.products, function (item) {
                          return _c("div", { key: item.id }, [
                            item.minimumQuantity
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      `${item.minimumQuantity} ${item.unitMetric}`
                                    )
                                  ),
                                ])
                              : _c("div", [_vm._v("-")]),
                          ])
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  1743701795
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Max Quantity",
                  "min-width": "100px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(scope.row.products, function (item) {
                          return _c("div", { key: item.id }, [
                            item.maximumQuantity
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      `${item.maximumQuantity} ${item.unitMetric}`
                                    )
                                  ),
                                ])
                              : _c("div", [_vm._v("-")]),
                          ])
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  698319715
                ),
              }),
              _vm.orderType === _vm.ORDER_TYPE.DELIVERED ||
              _vm.orderType === _vm.ORDER_TYPE.BARGING_NOMINATION ||
              _vm.orderType === "DELIVERED_AND_BARGING_NOMINATION"
                ? _c("el-table-column", {
                    attrs: {
                      label: "Physical Supplier",
                      "min-width": "150px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(scope.row.products, function (item) {
                              return _c("div", { key: item.id }, [
                                _vm._v(
                                  " " + _vm._s(item.physicalSupplierName) + " "
                                ),
                              ])
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      3885266214
                    ),
                  })
                : _vm._e(),
              _vm.hasTableMenu
                ? _c("el-table-column", {
                    key: "dropdownMenu",
                    attrs: { "min-width": "50px", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("dropdown-action-menu", {
                                attrs: {
                                  actionList: _vm.getOrderTableMenuList(
                                    scope.row
                                  ),
                                  propsData: scope.row,
                                },
                                on: {
                                  handleDropdownMenuClick:
                                    _vm.handleOrderTableMenuClick,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2313831485
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.hasPagination && !_vm.isOrderTableLoading
        ? _c(
            "div",
            { staticClass: "div-main-list-page-table-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.orderPagination.pageSize,
                  "page-sizes": [5, 10, 20, 50, 100],
                  "current-page": _vm.orderPagination.pageNumber,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.orderPagination.total,
                },
                on: {
                  "size-change": _vm.handleOrderPageSizeChange,
                  "current-change": _vm.handleOrderPageChange,
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.orderPagination, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.orderPagination, "pageSize", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }